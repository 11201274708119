import React from 'react'
import { graphql } from 'gatsby'
import { Grid } from '@material-ui/core'
import Pager from '../components/pagerKeyword'
import Master from '../components/Master'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import Products from '../components/ListView/Products/Products'

export default ({ data, pageContext }) => {
  const keyword = data.keyword
  const products = data.keyword.products.slice(pageContext.skip, pageContext.skip + pageContext.limit)
  let empty = ''
  if (products.length === 0) {
    empty = <div>Nenhuma festa ou personalizado foram encontrados.</div>
  }

  return (
    <Master title={keyword.title}>
      <GatsbySeo
        title={keyword.title}
        description={`Veja corerspondências de ${keyword.title} em ${process.env.SITE_NAME}`}
        canonical={`${process.env.SITE_URL}/tags/${keyword.slug}`}
        noindex={products.length === 0 ? true : false}
        nofollow={products.length === 0 ? true : false}
        openGraph={{
          url: `${process.env.SITE_URL}/tags/${keyword.slug}`,
          title: keyword.title,
          description: `Veja corerspondências de ${keyword.title} em ${process.env.SITE_NAME}`,
          images: [
            {
              url: `${process.env.SITE_IMAGE_OG_512}`,
              width: 512,
              height: 512,
              alt: keyword.title
            }
          ],
          site_name: `${process.env.SITE_NAME}`,
        }}
        twitter={{
          handle: '@handle',
          site: '@site',
          cardType: 'summary_large_image',
        }}
      />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <h1>{keyword.title}</h1>
          <Pager pageContext={pageContext} slug={keyword.slug} />
          <Products products={products} />
          <Pager pageContext={pageContext} slug={keyword.slug} />
          {empty}
        </Grid>
      </Grid>
    </Master>
  )
}

export const query = graphql`
  query($slug: String!) {
    keyword(slug: {eq: $slug}) {
      title
      slug
      products {
        id
        title
        slug
        price
        image
        imageThumbnail
        isEvent
      }
    }
  }
`